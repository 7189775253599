import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import AuthorizedRoute from './_routing/routes/AuthorizedRoute';
import AuthorizedLayout from './_routing/layouts/authorized/AuthorizedLayout';
import Toastify from './Toastify';
import ModalWrapper from './modal/ModalWrapper';

const App: React.FC = () => {
  return (
    <>
      <Switch>
        <AuthorizedRoute component={AuthorizedLayout} path="/" />
        <Redirect to="/" />
      </Switch>
      <ModalWrapper />
      <Toastify />
    </>
  );
};

export default App;
