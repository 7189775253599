import Faq from '../Faq';

export const faqs: Faq[] = [
  {
    answer:
      '<p>Endodontie is een driejarige specialisatie na het behalen van je tandartsdiploma en legt zich specifiek toe op complexere wortelkanaalbehandelingen, tandtrauma bij kinderen en microchirurgie.</p><p>Voor deze nauwkeurige behandelingen maken we gebruik van aangepast instrumentarium, een microscoop en indien nodig een 3D scan (Cone beam CT).</p><p>De tandarts kan u verwijzen bij een complexer verloop van de kanaaltjes, moeilijk te verdoven tanden, oude kanaalbehandelingen die niet voldoen, chirurgie en behandelingen bij kinderen.</p>',
    id: '0bc72967-bb54-4e7f-a884-abd27fc85dc2',
    question: 'Waarom werd u verwezen naar de endodontoloog',
  },
  {
    answer:
      '<p>Voor een kleinere kies met 1 of 2 kanaaltjes wordt meestal 1 uur voorzien en voor de grotere kiezen met 3 of meer kanaaltjes 1,5 uur.</p><p>Bijzondere behandelingen vragen soms extra behandeltijd.</p><p>We werken de wortelkanaalbehandeling meestal in 1 zittijd af zodat u niet hoeft terug te komen</p><p>De tijd die u moet voorzien wordt steeds bij het maken van de afspraak meegedeeld</p>',
    id: 'a6459185-89bf-4e2a-933e-03df13d1f3ac',
    question: 'Hoeveel tijd moet ik voorzien?',
  },
  {
    answer:
      '<p>Indien er voor de start van de behandeling niet goed of onvoldoende verdoofd wordt, zou dit pijnlijk kunnen zijn. Een perfect werkende verdoving is daarom dus een belangrijk onderdeel van een goede endodontische behandeling. De behandeling verloopt dan ook pijnvrij.</p>',
    id: 'aa750102-b1c6-4f35-abd7-2decc407bf44',
    question: 'Is een wortelkanaalbehandeling pijnlijk?',
  },
  {
    answer:
      '<p>Omwille van de complexiteit van de behandeling en de niet aangepaste RIZIV-nomenclatuur kan de conventie niet gevolgd worden. De uiteindelijke kostprijs kunnen we vaak goed van tevoren inschatten en wordt dan ook bij het maken van de afspraak meegedeeld. Soms komen we tijdens de behandeling voor verassingen te staan wat een beperkte extra kost met zich meebrengt, dit is eerder uitzonderlijk.</p><p>Het RIZIV zal ongeveer 40% tot 50% van de behandeling terugbetalen. Indien u een extra tandverzekering hebt, meldt u dit aan de balie. De assistente zal dan voor de nodige documenten zorgen.</p><p>De betaling gebeurt op het einde van de behandeling, bij voorkeur digitaal, uitzonderlijk cash maar niet met overschrijving.</p><p>Mocht u een afspraak niet kunnen nakomen, stellen we het ten zeerste op prijs dat u dit minimaal één werkdag ervoor meldt. Hierdoor wordt er geen onnodige stoeltijd en assistentie voorzien.</p><p>Bij laattijdige annulering of het niet nakomen van uw afspraak, wordt deze kost aan u doorgerekend.</p>',
    id: '0c2e0c7b-b618-49fb-a5ba-f13ba89693fd',
    question: 'Hoeveel kost een behandeling?',
  },
  {
    answer:
      '<p>In sommige gevallen kan u nog een beperkte gevoeligheid ervaren, wat overeenkomt met een klassieke tandartsbehandeling, indien nodig neemt u een pijnstiller (Dafalgan en/of Brufen).</p><p>Soms kan een bestaande ontsteking na de behandeling kortstondig opflakkeren, in dit geval schrijven we preventief antibiotica voor. U neemt deze antibiotica enkel indien de pijn toeneemt, bij zwelling of toenemende druk op de behandelde tand. U dient steeds het volledige doosje uit te nemen</p>',
    id: '13eb7256-068c-47ba-ada0-662d230aaca1',
    question: 'Zal u nog pijn hebben na de behandeling?',
  },
  {
    answer:
      '<p>Een tand verkleurt niet na een wortelkanaalbehandeling. Vroeger werden zilvercementen gebruikt die zwart verkleurden. Deze worden echter niet meer gebruikt. Als een tand verkleurd is kan deze na het uitvoeren van een wortelkanaalbehandeling opnieuw wit gemaakt worden.</p>',
    id: '5bc50af3-aff0-473f-a85a-4dcf93cef728',
    question: 'Verkleurt mijn tand na een wortelkanaalbehandeling?',
  },
];
