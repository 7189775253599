import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';

const AuthorizedRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = true;
  const { pathname } = useLocation();

  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn ? <Component {...props} /> : <Redirect to={{ pathname: '/auth/login', state: { pathname } }} />
      }
    />
  );
};

export default AuthorizedRoute;
