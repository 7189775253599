import React from 'react';
import GoogleMapReact from 'google-map-react';
import './map.scss';

const Map = () => {
  const center = {
    lat: 51.0364826,
    lng: 4.4339814,
  };
  const markerLatLng = {
    lat: 51.0364826,
    lng: 4.4339814,
  };
  const zoom = 16;

  function renderMarker(map, maps) {
    return new maps.Marker({
      map,
      position: markerLatLng,
    });
  }

  return (
    <div className="maps">
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyBc5t5scqOIxhgNmbxtZNGdGJl6iPomtQY' }}
        defaultCenter={center}
        defaultZoom={zoom}
        onGoogleApiLoaded={({ map, maps }) => renderMarker(map, maps)}
        yesIWantToUseGoogleMapApiInternals
      ></GoogleMapReact>
    </div>
  );
};

export default Map;
