import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { combineReducers, Reducer, Action } from 'redux';
import modalReducer, { ModalState } from '../_routing/_store/reducer';

export type AppState = {
  modal: ModalState;
  router: RouterState;
};

function appReducer(history: History): Reducer {
  return combineReducers<AppState>({
    modal: modalReducer,
    router: connectRouter(history),
  });
}

export default (history: History) => (state: AppState, action: Action) => {
  return appReducer(history)(state, action);
};
