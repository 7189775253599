import React from 'react';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import Social from '../social/Social';
import './footer.scss';

const Footer = () => {
  const { pathname } = useLocation();
  const isAbsolute = [
    '/veelgestelde-vragen',
    '/voor-tandartsen',
    '/voor-tandartsen/verwijzen',
    '/voor-tandartsen/opleidingen',
    '/covid',
  ].includes(pathname);

  return (
    <footer className={classnames({ isAbsolute })}>
      <div className="column-wrapper">
        <div className=" column column-one">
          <Social type="mail" />
          <Social />
        </div>
        <div className=" column column-two">
          <Social type="location" />
          <p className=" column column-four">Open van ma - vrij</p>
        </div>
        <div className=" column column-three">
          <Social text="/Kanaal95" type="fb" />
          <Social text="/Kanaal95" type="ig" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
