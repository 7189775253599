import React from 'react';
import InfoBlock, { Info } from '../../_shared/infoBlock/InfoBlock';
import '../doctors.scss';

const info: Info[] = [
  {
    destination: '/voor-tandartsen/verwijzen',
    src: require('../../_assets/images/artsen/Verwijzen.jpg'),
    title: 'Verwijzen',
  },
  {
    destination: '/voor-tandartsen/opleidingen',
    src: require('../../_assets/images/artsen/opleidingen.jpg'),
    title: '"Opleidingen" voor tandartsen',
  },
];

const DoctorsHome = () => {
  return (
    <div className="doctors-wrapper">
      <div className="header">
        <img alt="praktijk" src={require('../../_assets/images/artsen/Hoofdfoto.png')} />
        <div className="title-wrapper">
          <h1>
            Welkom bij <span>Kanaal95</span> voor <span>Tandartsen</span>
          </h1>
          <p>
            Hartelijk dank voor het vertrouwen en het verwijzen van uw patiënt. Wij stellen alles in het werk voor een fijne,
            professionele samenwerking en een aangename ervaring voor uw patiënt met kwaliteitsvolle endodontie als resultaat.
          </p>
        </div>
      </div>
      <div className="body">
        {info.map((i: Info) => (
          <InfoBlock info={i} key={i.title} />
        ))}
      </div>
    </div>
  );
};

export default DoctorsHome;
