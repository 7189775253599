import React from 'react';
import { Links } from '../_routing/layouts/menu/AuthorizedLayoutMenu';
import './team.scss';
import TeamItem from './_components/TeamItem';

const doctors = [
  {
    eduction: [
      '2001: Master in de tandheelkunde KUL',
      '2004: Postgraduaat en Master in de endodontologie KUL',
      'Oprichting ‘Kanaal 95’ in 2012 door Koen Lissens',
    ],
    name: 'koen',
    quote: '“Always patient with my patient“',
  },
  {
    eduction: [
      '2010: Master in de revalidatiewetenschappen en kinesitherapie KULeuven',
      '2016: Master in de algemene tandheelkunde KULeuven',
      '2017: Postgraduaat in de algemene tandheelkunde KULeuven',
      '2020: Postgraduaat en Master in de endodontologie KULeuven',
      'Certified member van ESE (European Society of Endodontology)',
      'Associatie in ‘Kanaal 95’ sinds 2020',
    ],
    name: 'kathleen',
    quote: '“Geen twee, maar liever drie puntjes op de ‘i’“',
  },
  {
    eduction: [
      '2012: Master of science in de tandheelkunde KUL',
      '2013: Postgraduaat in de algemene tandheelkunde KUL',
      '2015: Postgraduaat en Master in de endodontologie KUL',
      'Sinds 2015: consulent endodontie aan <a href="https://www.uzleuven.be/nl/artsen-en-specialisten/olaf-strijbos" rel="noopener noreferrer" target="_blank">KUL/UZleuven</a>  met focus op apicale microchirurgie, telkens op vrijdag',
      'één donderdag in de maand werkzaam als endodontoloog in tandartspraktijk <a href="https://www.bitewing.be" rel="noopener noreferrer" target="_blank">‘Bitewing’</a> in Herent.',
      'Associatie in ‘Kanaal 95’ sinds 2017',
      'Certified member van ESE (European Society of Endodontology)',
      'Lid van FSfE, BAET en AAE',
    ],
    name: 'olaf',
    quote: '“Pleasure in the job puts perfection in the work” - Aristoteles',
  },
];

const Team = () => {
  return (
    <div className="team">
      <h1>{Links.TEAM.toUpperCase()}</h1>
      <img alt="groeps-foto" src={require('../_assets/images/team/Groepsfoto.jpg')} />

      <div className="docters-wrapper">
        {doctors.map(person => (
          <TeamItem key={person.name} person={person} />
        ))}
      </div>
      <TeamItem isNotPersonal nameAssis="Oceane en Yuna" />
      <p>
        Onze assistentes Oceane en Yuna zorgen voor een goede en aangename ontvangst en staan u telefonisch te woord bij het
        nauwkeurig inplannen van uw afspraak. Bij hen kan u altijd terecht voor verdere vragen of opmerkingen. Verder zorgen zij
        ervoor dat in onze praktijk gewerkt wordt volgens strikte hygiënerichtlijnen.
      </p>
    </div>
  );
};

export default Team;
