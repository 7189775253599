import React from 'react';
import { useWindowSize } from '../../_hooks';
import './refers.scss';

const Refers = () => {
  const browserWidth = useWindowSize();

  const renderDesktopVersion = () => (
    <div className="list-wrapper">
      <div>
        <ul>
          <li>Endodontisch advies</li>
          <li>Behandeling immature tand</li>
          <li>Post-endodontische opbouw</li>
          <li>Endodontische microchirurgie</li>
        </ul>
      </div>
      <div>
        <ul>
          <li>Cone Beam CT</li>
          <li>Herbehandeling</li>
          <li>Wortelkanaalbehandeling</li>
          <li>Trauma</li>
        </ul>
      </div>
      <div>
        <ul>
          <li>Vituale Pulpatherapie</li>
          <li>Avitaal bleken</li>
          <li>Resorptie</li>
        </ul>
      </div>
    </div>
  );

  const renderMobileVersion = () => (
    <div className="list-wrapper">
      <div>
        <ul>
          <li>Endodontisch advies</li>
          <li>Behandeling immature tand</li>
          <li>Post-endodontische opbouw</li>
          <li>Endodontische microchirurgie</li>
          <li>Cone Beam CT</li>
          <li>Herbehandeling</li>
          <li>Wortelkanaalbehandeling</li>
          <li>Vituale Pulpatherapie</li>
          <li>Avitaal bleken</li>
          <li>Resorptie</li>
        </ul>
      </div>
    </div>
  );
  return (
    <div className="refers">
      <h1>Verwijzen</h1>
      <p className="sentence">U kan uw patiënt met een gerust hart naar onze praktijk doorverwijzen voor:</p>
      {browserWidth <= 1004 ? renderMobileVersion() : renderDesktopVersion()}
      <div className="document-wrapper">
        <h2>Sjabloon</h2>
        <p>Download onderstaand sjabloon, vul deze in en stuur ons dit formulier door, indien nodig met bijkomende documenten.</p>
        <a
          className="download"
          download="sjabloon.docx"
          href={`${process.env.PUBLIC_URL}/_assets/sjabloon.docx`}
          rel="noopener noreferrer"
          target="_blank"
        >
          Download sjabloon
        </a>
      </div>
    </div>
  );
};

export default Refers;
