import React, { FC } from 'react';
import classnames from 'classnames';
import { Icon } from '../../../_shared';
import './treamentItem.scss';
import { useToggle, useWindowSize } from '../../../_hooks';

type Props = {
  description: string;
  img: string;
  isReversed: boolean;
  title: string;
  video: string;
};

const TreatmentItem: FC<Props> = ({ isReversed, description, title, video, img }) => {
  const [canReadMore, setReadMore] = useToggle(false);
  const firstPart = description.split('splitter')[0];
  const secondPart = description.split('splitter')[1];
  const width = useWindowSize();
  const isMobile = width < 1000;

  function handleVideoClick() {
    window.open(video);
  }

  return (
    <div className={classnames('treatment-item', { isReversed })}>
      {(!isReversed || isMobile) && <img alt="picture" src={img} />}
      <div className="info-wrapper">
        <div className="title-wrapper">
          <h2>{title}</h2>
          {!!video && <Icon name="SvgPlay" onClick={handleVideoClick} size={2.2} />}
        </div>
        <span dangerouslySetInnerHTML={{ __html: firstPart }} />
        {secondPart && (
          <>
            {!canReadMore && (
              <span className="more" onClick={() => setReadMore(true)}>
                Lees meer
              </span>
            )}
            {canReadMore && <span dangerouslySetInnerHTML={{ __html: secondPart }} />}
            {canReadMore && (
              <span className="more" onClick={() => setReadMore(false)}>
                Lees minder
              </span>
            )}
          </>
        )}
      </div>
      {isReversed && !isMobile && <img alt="picture" src={img} />}
    </div>
  );
};

export default TreatmentItem;
