import React from 'react';
import { Links } from '../_routing/layouts/menu/AuthorizedLayoutMenu';
import './faq.scss';
import FaqItem from './faq-item/FaqItem';
import { faqs } from './_utils/questions';

type Faq = {
  answer: string;
  id: string;
  question: string;
};

const Faq = () => {
  const title = Links.FAQ.split('-');

  return (
    <section className="faq">
      <h1>{`${title[0].toUpperCase()} ${title[1].toUpperCase()}`}</h1>
      <div className="body">
        {faqs.map((faq: Faq, i) => (
          <FaqItem faq={faq} isLastItem={i + 1 === faqs.length} key={faq.id} />
        ))}
      </div>
    </section>
  );
};

export default Faq;
