import { Action } from 'redux';

export enum ModalActionType {
  OpenMobileModal = '[Modal] OpenMobileModal',
}

export class OpenMobileModal implements Action<ModalActionType> {
  readonly type = ModalActionType.OpenMobileModal;
}

export type ModalAction = OpenMobileModal;
