/* eslint-disable sort-keys-fix/sort-keys-fix */
import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import { Button, Icon } from '../../../_shared';
import { useWindowSize } from '../../../_hooks';
import { modalActions } from '../../../_store/actions';
import { routerSelectors } from '../../../_store/selectors';
import './authorizedLayoutMenu.scss';

export const Links = {
  HOME: 'home',
  TREATMENTS: 'behandelingen',
  TEAM: 'team',
  CONTACT: 'contact',
  DOCTORS: 'voor-tandartsen',
  FAQ: 'veelgestelde-vragen',
};

const AuthorizedLayoutMenu: FC = () => {
  const { pathname } = useLocation();
  const currentRoute = pathname.split('/')[1];
  const [activeItem, setActiveItem] = useState(currentRoute);
  const isMobileModalOpen = useSelector(routerSelectors.isMobileModalOpen);
  const browswerWidth = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    setActiveItem(currentRoute);
  }, [pathname]);

  const renderNavLink = (link: string): string => {
    if (link.includes('-')) {
      const navlink = link.split('-');
      return `${navlink[0].toUpperCase()} ${navlink[1].toUpperCase()}`;
    }
    return link.toUpperCase();
  };

  const renderMobileNav = () => {
    function goToRoute(link: string) {
      setActiveItem(link);
      dispatch(new modalActions.OpenMobileModal());
    }

    return (
      <div>
        <Icon className="close" name="SvgClose" onClick={() => dispatch(new modalActions.OpenMobileModal())} size={2.5} />
        <div className="mobile-nav">
          {Object.values(Links).map(link => (
            <div className="link-wrapper" key={link}>
              <NavLink
                className={classnames({ 'is-active': activeItem === link })}
                onClick={() => goToRoute(link)}
                to={`/${link}`}
              >
                {renderNavLink(link)}
              </NavLink>
              {activeItem === link && <div className="underline" />}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <header className="main-menu">
      {isMobileModalOpen && renderMobileNav()}
      <NavLink className="logo" to="/">
        <img alt="logo" src={require('../../../_assets/images/logo.png')} />
      </NavLink>
      <nav>
        {Object.values(Links).map(link => (
          <div className="link-wrapper" key={link}>
            <NavLink
              className={classnames({ 'is-active': activeItem === link })}
              onClick={() => setActiveItem(link)}
              to={`/${link}`}
            >
              {renderNavLink(link)}
            </NavLink>
            {activeItem === link && <div className="underline" />}
          </div>
        ))}
        {browswerWidth >= 1280 && (
          <Button href="/contact" theme="primary">
            Maak afspraak
          </Button>
        )}
      </nav>
      {browswerWidth <= 1280 && (
        <div className="mobile-nav-wrapper">
          <Button href="/contact" theme="primary">
            Maak afspraak
          </Button>
          <Icon
            className={classnames({ 'is-invisible': isMobileModalOpen })}
            name="SvgMenu"
            onClick={() => dispatch(new modalActions.OpenMobileModal())}
            size={2}
          />
        </div>
      )}
    </header>
  );
};

export default AuthorizedLayoutMenu;
