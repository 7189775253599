import React from 'react';
import { Button } from '../../../_shared';
import './covid.scss';

const Covid = () => {
  return (
    <div className="covid">
      <h1>COVID-19</h1>
      <img alt="covid" src={require('../../../_assets/images/covid/Covid.jpg')} />
      <p>
        Omwille van de Covid-19-pandemie treffen wij bijkomende maatregelen om de bescherming van de patiënt en de tandarts te
        waarborgen.
      </p>
      <div>
        <p>Mogen we voor het volgende uw aandacht vragen:</p>
        <ul>
          <li>
            - Gelieve uw afspraak telefonisch te annuleren als u zich niet 100% voelt (koorts, smaak/geur- verlies, hoesten,
            vermoeidheid, etc).
          </li>
          <li>- Gelieve juist op tijd te komen voor uw afspraak, niet te vroeg.</li>
          <li>- Gelieve alleen te komen op uw afspraak.</li>
          <li>
            - Gelieve bij aankomst uw mond te spoelen met het voorziene mondspoelmiddel en de handen te ontsmetten met alcoholgel.
          </li>
          <li>- Gelieve zo weinig mogelijk persoonlijke spullen mee te nemen (jas/tas).</li>
          <li>- Gelieve geen gebruik te maken van het toilet (tenzij het uiteraard niet anders kan).</li>
          <li>- Gelieve met bancontact te betalen.</li>
        </ul>
      </div>
      <h3>Wil je graag een afspraak maken?</h3>
      <Button href="/contact" theme="primary">
        Maak afspraak
      </Button>
    </div>
  );
};

export default Covid;
