import React, { FC } from 'react';
import { Icon } from '..';
import './social.scss';

type Props = {
  className?: string;
  text?: string;
  type?: 'location' | 'mail' | 'phone' | 'ig' | 'fb';
};

const Social: FC<Props> = ({ type = 'phone', text, className }) => {
  if (type === 'location') {
    return (
      <div className="social">
        <a href="https://maps.google.com/?q=Kanaal95" rel="noopener noreferrer" target="_blank">
          <Icon name="SvgLocation" size={2.2} />
          <p>Oude Leestsebaan 124a,</p>
          <p>2800 Mechelen</p>
        </a>
      </div>
    );
  }
  if (type === 'phone') {
    return (
      <div className="social">
        <a href="tel:015643679" rel="noopener noreferrer" target="_blank">
          <Icon name="SvgPhone" size={2.2} />
          <div className="text-wrapper">
            <p>015 64 36 79</p>
            <p className="text-info">Elke dag tussen 9u - 12u en 14u - 17u</p>
          </div>
        </a>
      </div>
    );
  }
  if (type === 'fb') {
    return (
      <div className="social">
        <a href="https://www.facebook.com/Kanaal-95-100550268704371" rel="noopener noreferrer" target="_blank">
          <Icon name="SvgFb" size={2.2} />
          <p>{text}</p>
        </a>
      </div>
    );
  }
  if (type === 'ig') {
    return (
      <div className="social">
        <a href="https://www.instagram.com/kanaal95/" rel="noopener noreferrer" target="_blank">
          <Icon name="SvgIg" size={2.2} />
          <p>{text}</p>
        </a>
      </div>
    );
  }
  return (
    <div className={`social ${className}`}>
      <a href="mailto:info@kanaal95.be" rel="noopener noreferrer" target="_blank">
        <Icon name="SvgMail" size={2.2} />
        <p>info@kanaal95.be</p>
      </a>
    </div>
  );
};

export default Social;
