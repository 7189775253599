import React, { FC } from 'react';
import Faq from '../Faq';
import { useToggle } from '../../_hooks';

type Props = {
  faq: Faq;
  isLastItem: boolean;
};

const FaqItem: FC<Props> = ({ faq: { answer, id, question }, isLastItem }) => {
  const [isOpen, setOpen] = useToggle(false);

  return (
    <>
      <div className="faq-wrapper" key={id} onClick={() => setOpen(!isOpen)}>
        <h2>{question}</h2>
      </div>
      {isOpen && <div className={isLastItem && 'isLastItem'} dangerouslySetInnerHTML={{ __html: answer }} />}
    </>
  );
};

export default FaqItem;
