import { ModalAction, ModalActionType } from './actions';

export type ModalState = {
  isMobileModalOpen: boolean;
};

const initialState: ModalState = {
  isMobileModalOpen: false,
};

export default function reducer(state = initialState, action: ModalAction): ModalState {
  switch (action.type) {
    case ModalActionType.OpenMobileModal:
      return {
        ...state,
        isMobileModalOpen: !state.isMobileModalOpen,
      };
    default:
      return state;
  }
}
