import React from 'react';
import { Links } from '../_routing/layouts/menu/AuthorizedLayoutMenu';
import Social from '../_shared/social/Social';
import Map from './map/Map';
import './contact.scss';

function capitalizeFirstLetter(value: string): string {
  return `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
}

const Contact = () => {
  return (
    <div className="contact-wrapper">
      <div className="top">
        <div className="contact-info-section">
          <h1>{capitalizeFirstLetter(Links.CONTACT)}</h1>
          <p className="subtitle">Wilt u een afspraak maken?</p>
          <div>
            <Social type="phone" />
            <Social className="contact-mail" type="mail" />
            <p className="open">Open van ma - vrij</p>
            <div className="socials">
              <Social text="/Kanaal95" type="fb" />
              <Social text="/Kanaal95" type="ig" />
            </div>
          </div>
        </div>
        <div className="contact-info-section">
          <h1>Checklist</h1>
          <p className="subtitle checklist-info">Wat brengt u mee naar uw afspraak?</p>
          <ul>
            <li>eID</li>
            <li>
              Verwijsbrief (download sjabloon{' '}
              <a
                className="download"
                download="sjabloon.docx"
                href={`${process.env.PUBLIC_URL}/_assets/sjabloon.docx`}
                rel="noopener noreferrer"
                target="_blank"
              >
                hier
              </a>
              )
            </li>
            <li>Medicatie lijst</li>
          </ul>
        </div>
      </div>
      <div className="contact-info-section">
        <h1>Locatie</h1>
        <p>Oude Leestsebaan 124a,</p>
        <p>2800 Mechelen</p>
        <img alt="praktijk" className="praktijk" src={require('../_assets/images/contact/building_one.jpeg')} />
        <img alt="praktijk" className="praktijk" src={require('../_assets/images/contact/building_two.jpeg')} />
        <Map />
        <a
          className="maps-button"
          href="https://www.google.com/maps/place/51°02'11.4%22N+4°26'02.5%22E/@51.036503,4.431841,17z/data=!3m1!4b1!4m6!3m5!1s0x0:0x55d8d88282be07bc!7e2!8m2!3d51.0365032!4d4.4340347"
          rel="noopener noreferrer"
          target="_blank"
        >
          Open in maps
        </a>
      </div>
    </div>
  );
};

export default Contact;
