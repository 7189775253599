import { createSelector } from 'reselect';
import { RouterState } from 'connected-react-router';
import { AppState } from '../../_store/rootReducer';
import { ModalState } from './reducer';

const selectNode = (state: AppState) => state.router;
const selectNodeTwo = (state: AppState) => state.modal;

export const pathname = createSelector(selectNode, (state: RouterState) => state.location.pathname);
export const isMobileModalOpen = createSelector(selectNodeTwo, (state: ModalState) => state.isMobileModalOpen);
