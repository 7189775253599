import React, { FC } from 'react';
import classnames from 'classnames';
import '../team.scss';

type Props = {
  isNotPersonal?: boolean;
  nameAssis?: string;
  person?: {
    eduction: string[];
    name: string;
    quote: string;
  };
};

const TeamItem: FC<Props> = ({ isNotPersonal, person, nameAssis }) => {
  function capitalizeFirstLetter(name: string) {
    return name.charAt(0).toUpperCase() + name.slice(1);
  }

  return (
    <div className="person-wrapper">
      <div className={classnames('person', { isNotPersonal })}>
        {isNotPersonal ? (
          <img alt="assistents" className="ysy" src={require('../../_assets/images/team/assistents.jpg')} />
        ) : (
          <img alt="doctors" src={require(`../../_assets/images/team/${person?.name}.jpg`)} />
        )}
        <div>
          <span>{isNotPersonal ? nameAssis : capitalizeFirstLetter(person?.name) || nameAssis}</span>
        </div>
      </div>
      <p className="quote">{person?.quote}</p>
      {person?.eduction.map(item => (
        <p className="education" dangerouslySetInnerHTML={{ __html: item }} key={item} />
      ))}
    </div>
  );
};

export default TeamItem;
