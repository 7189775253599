import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Button, Icon } from '../_shared';
import { Links } from '../_routing/layouts/menu/AuthorizedLayoutMenu';
import InfoBlock, { Info } from '../_shared/infoBlock/InfoBlock';
import './home.scss';
import Social from '../_shared/social/Social';

const Home = () => {
  const [isSpoedOpen, setSpoedOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState<number>(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (imageIndex === 5) {
      setTimeout(() => setImageIndex(0), 5000);
    } else {
      setTimeout(() => setImageIndex(imageIndex + 1), 5000);
    }
  }, [imageIndex]);

  const treatments: Info[] = [
    {
      src: require('../_assets/images/home/Spoedbehandelingen.png'),
      title: 'Spoedbehandeling',
    },
    {
      destination: `/${Links.TREATMENTS}`,
      src: require('../_assets/images/home/CBTC.jpg'),
      title: 'CBCT',
    },
    {
      destination: `/${Links.TREATMENTS}`,
      src: require('../_assets/images/behandelingen/Endodontische microchirurgie.jpg'),
      title: 'Endodontische microchirurgie',
    },
    {
      destination: `/covid`,
      src: require('../_assets/images/home/Covid.jpg'),
      title: 'COVID-19',
    },
  ];

  return (
    <>
      {isSpoedOpen && (
        <div className="spoed-modal">
          <Icon className="close" name="SvgClose" onClick={() => setSpoedOpen(false)} size={2.5} />
          <h2>Spoedbehandeling</h2>
          <p>
            Dagelijks worden er een aantal tijdssloten voorzien voor een eerste evaluatie en/of verhelpen van ernstige pijn. Ook
            de opvang van tandtrauma krijgt hier zijn plaats. Vaak zal een vervolgafspraak nodig zijn.
          </p>
          <h3>Voor meer informatie</h3>
          <Social type="mail" />
          <Social type="phone" />
        </div>
      )}
      <section className="home">
        <div className="header">
          <div className="info-wrapper">
            <h1>
              Welkom bij <span>Kanaal95</span> verwijspraktijk voor <span>endodontie</span> in Mechelen
            </h1>
            <div className="actions">
              <Button href="/contact" theme="primary">
                Maak afspraak
              </Button>
              <Button onClick={() => dispatch(push(Links.DOCTORS))} theme="negative">
                ik ben tandarts
              </Button>
            </div>
          </div>
          <img alt="praktijk" src={require(`../_assets/images/home/home${imageIndex}.png`)} />
        </div>
        <div className="body">
          {treatments.map((treatment: Info) => (
            <InfoBlock info={treatment} key={treatment.title} modalOpener={() => setSpoedOpen(true)} />
          ))}
        </div>
      </section>
    </>
  );
};

export default Home;
