import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Educations from './educations/Educations';
import DoctorsHome from './home/DoctersHome';
import Refers from './refers/Refers';
import './doctors.scss';

const Doctors = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route component={DoctorsHome} exact path={`${url}`} />
      <Route component={Refers} path={`${url}/verwijzen`} />
      <Route component={Educations} path={`${url}/opleidingen`} />
    </Switch>
  );
};

export default Doctors;
