import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Home from '../../../home/Home';
import { Footer } from '../../../_shared';
import Treatments from '../../../treatments/Treatments';
import Team from '../../../team/Team';
import Contact from '../../../contact/Contact';
import Doctors from '../../../doctors/Doctors';
import Faq from '../../../faq/Faq';
import AuthorizedLayoutMenu, { Links } from '../menu/AuthorizedLayoutMenu';
import { Covid } from '../../../home/_pages';
import './authorizedLayout.scss';

const AuthorizedLayout: React.FC = () => {
  return (
    <div className="authorized-layout">
      <AuthorizedLayoutMenu />
      <Switch>
        <Route component={Home} exact path="/" />
        <Route component={Covid} exact path="/covid" />
        <Route component={Treatments} exact path={`/${Links.TREATMENTS}`} />
        <Route component={Team} exact path={`/${Links.TEAM}`} />
        <Route component={Contact} exact path={`/${Links.CONTACT}`} />
        <Route component={Doctors} path={`/${Links.DOCTORS}`} />
        <Route component={Faq} exact path={`/${Links.FAQ}`} />
        <Redirect to="/" />
      </Switch>
      <Footer />
    </div>
  );
};

export default AuthorizedLayout;
