export const treatments = [
  {
    description:
      '<p>Binnenin de tand bevindt er zich een zenuw.</p><p>Wanneer deze zenuw geïrriteerd wordt, door bijvoorbeeld koude, warmte, zoet, zuur,… via een gaatje of een barst, zal deze een pijnprikkel doorgeven.Als dit proces onomkeerbaar is zal de zenuw spontaan een scherpe pijn doorgeven en moet deze verwijderd worden.</p><p>Een holle ruimte blijft nu over in de tand (kanaaltjes). Deze kanaaltjes krijgen een vorm, worden gereinigd en erna opgevuld. Mocht dit niet gebeuren, krijgen bacteriën vrij spel en gaan ze zich vermenigvuldigen in uw tand.</p><p><Na verloop van tijd komen ze aan de onderzijde van de wortelpunt terecht en geven daar aanleiding tot een infectie in het onderliggende bot. Wanneer deze infectie opflakkert zal dit eerder een doffe pijn geven met mogelijks een zwelling van het tandvlees (abces).</p><p>Er wordt steeds zeer goed verdoofd waardoor deze behandeling pijnvrij verloopt!</p>',
    img: require('../../_assets/images/behandelingen/Endodontische behandeling.jpg'),
    title: 'Wortelkanaal behandeling ',
    video: 'https://www.youtube.com/watch?v=JLeiDmOVfcg',
  },
  {
    description:
      '<p>Het kan aangewezen zijn dat een oude wortelkanaalbehandeling opnieuw uitgevoerd wordt.</p><p>De aanleiding tot herbehandeling kan divers zijn.</p><p>Oude vultechnieken die niet meer voldoen aan de huidige standaard, gemiste kanaaltjes of kanaaltjes die niet helemaal opgevuld werden.</p><p>Dit kan aanleiding geven tot een chronische infectie aan de wortelpunten.</p><p>Hierbij moet dan eerst de oude kanaalvulling verwijderd worden. Het oorspronkelijke kanaalverloop wordt tot op de puntjes gereinigd en gevuld. Deze behandeling is complexer dan een klassieke behandeling en vraagt vaak meer tijd.</p>',
    img: require('../../_assets/images/behandelingen/Herbehandeling.jpg'),
    title: 'Herbehandeling',
    video: 'https://www.youtube.com/watch?v=xlYYc7_BFTI',
  },
  {
    description:
      '<p>Een wortelkanaalbehandeling wordt tegenwoordig met veel succes uitgevoerd. Het komt echter soms voor dat na een wortelkanaalbehandeling een tandabces niet (volledig) geneest of dat opnieuw klachten ontstaan. In de meeste gevallen kan dan door een kleine chirurgische behandeling uw tand alsnog behouden blijven.</p><p>Na het geven van een plaatselijke verdoving kan de tandarts via een kleine insnede in het tandvlees bij de wortelpunt het ontstekingsweefsel gemakkelijk wegnemen.</p><p>Tot slot brengt de tandarts het tandvlees met enkele hechtdraadjes weer op zijn plaats.</p><p>Na de behandeling zal uw tandarts u vertellen hoe u de plek van de operatie het beste verzorgt. De eerste 2 tot 3 dagen na het ingreepje kan u soms wat zwelling en napijn verwachten.</p>',
    img: require('../../_assets/images/behandelingen/Endodontische microchirurgie.jpg'),
    title: 'Endodontische microchirurgie',
    video: 'https://www.youtube.com/watch?v=B2zzXiXQbU0',
  },
  {
    description:
      '<p>Bij kinderen kunnen zowel de melktanden als definitieve tanden ontzenuwd worden. Met behulp van microscopische precisie en het gebruik van speciale biocompatibele materialen worden deze tanden terug pijn- en ontstekingsvrij gemaakt.</p><p>Ook valpartijen met tandtraumata kunnen leiden tot ontzenuwing van een tand.</p>',
    img: require('../../_assets/images/behandelingen/Endo bij kinderen.jpg'),
    title: 'Endodontie bij kinderen',
  },
  {
    description:
      '<p>Na tandbederf of tandtrauma, kan een tand afsterven, waarbij deze van kleur verandert. Ook door verkalkingen in de tand zelf of na een vroegere kanaalbehandeling, kan de tand vanbinnen donker worden.</p><p>Een interne bleking is dan mogelijk na het uitvoeren van een kanaalbehandeling, waardoor de tand terug lichter van kleur zal worden. Het kan nodig zijn deze behandeling te herhalen tot het gewenste resultaat bereikt is.</p>',
    img: require('../../_assets/images/behandelingen/Avitaal bleken.jpg'),
    title: 'Avitaal bleken',
  },
  {
    description:
      '<p>Dit toestel maakt een 3D opname van de tanden en de omliggende weefsels.</p><p>Hiermee kan er verder gekeken worden dan met de klassieke 2D radiografie.</p><p>Het geeft ons de mogelijkheid om vóór de behandeling in te schatten wat de oorzaak van de klachten kan zijn, of behandeling al dan niet aangewezen is en wat de juiste aanpak wordt.</p>',
    img: require('../../_assets/images/behandelingen/3DScan.jpg'),
    title: '3D scan (Cone Beam CT)',
  },
];
