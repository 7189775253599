import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import './infoBlock.scss';

export type Info = {
  destination?: string;
  src: string;
  title: string;
};

type Props = {
  info: Info;
  modalOpener?: () => void;
};

const InfoBlock: FC<Props> = ({ info, modalOpener }) => {
  const { destination, src, title } = info;
  const dispatch = useDispatch();

  return (
    <div className="info-block-wrapper" onClick={title === 'Spoedbehandeling' ? modalOpener : () => dispatch(push(destination))}>
      <>
        <p>{title}</p>
        <img alt={title} src={src} />
      </>
    </div>
  );
};

export default InfoBlock;
