import React from 'react';
import { Links } from '../_routing/layouts/menu/AuthorizedLayoutMenu';
import SvgArrow from '../_assets/svg/arrow.png';
import TreatmentItem from './_components/treamentItem/TreatmentItem';
import { treatments } from './_utils/info';
import './treatment.scss';

const Treatments = () => {
  return (
    <div className="treatment">
      <h1>ONZE {Links.TREATMENTS.toUpperCase()}</h1>
      <p>
        Bij ons in de praktijk kan u terecht voor behandeling van het wortelgedeelte van uw tand (=endodontium). Wij richten ons
        op wortelkanaalbehandelingen bij volwassenen en kinderen, alsook op endodontische microchirurgie.
      </p>
      <div className="body">
        {treatments.map(({ description, title, video, img }, index) => (
          <TreatmentItem
            description={description}
            img={img}
            isReversed={index % 2 !== 0}
            key={title}
            title={title}
            video={video}
          />
        ))}
      </div>
      <img
        alt="arrow"
        className="arrow"
        onClick={() =>
          window.scrollTo({
            behavior: 'smooth',
            left: 0,
            top: 0,
          })
        }
        src={SvgArrow}
      />
    </div>
  );
};

export default Treatments;
