import React from 'react';
import Social from '../../_shared/social/Social';
import './educations.scss';

const Educations = () => {
  const educations = [
    {
      date: '27/03/2021',
      flyer: `${process.env.PUBLIC_URL}/_assets/uitnodigingMaart.pdf`,
      title: '“Endo interactief met live demo” (Indien Covid-19 maatregelen dit toelaten)',
    },
  ];

  return (
    <div className="educations">
      <h1>Opleidingen voor tandartsen</h1>
      <img alt="opleiding" src={require('../../_assets/images/opleidingen/Opleidingvoorartsen.jpg')} />
      <div className="dates">
        <h2>Data cursussen</h2>
        {educations.map(({ date, title, flyer }) => (
          <div className="education-wrapper" key={date}>
            <p>{title}</p>
            <div>
              <p className="date">{date}</p>
              {flyer && (
                <a
                  download="Uitnodiging cursus 14 maart 2020 - Olaf Strijbos, Koen Lissens en Kathleen Lerut"
                  href={flyer}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Download flyer voor meer info
                </a>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="more-info">
        <h2>Wil je graag meer informatie?</h2>
        <p>
          Neem gerust telefonisch of via mail contact met ons op zodat we u verder kunnen inlichten omtrent een of meerdere van
          onze opleidingen.
        </p>
        <div>
          <Social type="mail" />
          <Social type="phone" />
        </div>
      </div>
    </div>
  );
};

export default Educations;
